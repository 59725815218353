/**
 * Load a script from a URL and add it to the document.
 * @param url The URL of the script to load.
 * @param id The ID of the script element.
 * @returns A promise that resolves when the script has loaded.
 * @example
 * ```ts
 * await loadScript("https://example.com/script.js", "example-script");
 * ```
 */
export const loadScript = (url: string, id: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const existingScript = document.getElementById(id);

    if (existingScript) {
      if (existingScript.getAttribute("data-loaded") === "true") {
        resolve();
        return;
      } else {
        existingScript.addEventListener("load", () => {
          existingScript.setAttribute("data-loaded", "true");
          resolve();
        });
        existingScript.addEventListener("error", (err) => reject(err));
        return;
      }
    }
    const script = document.createElement("script");
    script.src = url;
    script.id = id;
    script.onload = () => {
      script.setAttribute("data-loaded", "true");
      resolve();
    };
    script.onabort = () => reject("Error: Script aborted");
    script.onerror = (err) => reject(err);

    document.body.appendChild(script);
  });
};

export const loadPdfJs = () =>
  loadScript(
    "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.min.js",
    "pdfjs"
  );

export const loadTinyMce = () =>
  loadScript(
    "https://cdnjs.cloudflare.com/ajax/libs/tinymce/7.3.0/tinymce.min.js",
    "tinymce"
  );

export const loadToastUiCalendar = () =>
  loadScript(
    "https://uicdn.toast.com/calendar/latest/toastui-calendar.min.js",
    "toastui-calendar"
  );

export const loadEcharts = () =>
  loadScript(
    "https://cdnjs.cloudflare.com/ajax/libs/echarts/5.5.0/echarts.min.js",
    "echarts"
  );
